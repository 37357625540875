//
// Custom Example 1 Style
// Here you can put your custom sass code, use global SASS mixings and variables to override the theme or add new styles according to your requirements.
// Most of the changes can be achieved via global or layout level sass variable changes.
//


.my-custom-style {
    padding: 1rem 0.5rem;
    color: kt-brand-color();

    .my-custom-element {
        margin-left: 1rem;
    }
}

.hms-message-full{
    text-align: center;
    opacity: .3;
    padding: 3rem;
    color: #343a40;
}

.hms-text-center{
    text-align: center;
}

.hms-button-center{
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 1rem 3rem;
}

@include kt-tablet-and-mobile {
    .my-custom-style {
        padding: 0.5rem 0.25rem;

        .my-custom-element {
            margin-left: 0.5rem;
        }
    }
}
